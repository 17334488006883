.App {
  text-align: center;
  /* font-family: 'Open Sans', sans-serif !important; */
  /* font-style: italic !important; */
  /* background-image:url(./Images/heroImages_1.png);
  background-size: 2000px 2000px; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.css-untgvu-MuiPaper-root-MuiAppBar-root{
  background: none !important;
  box-shadow: none !important;
}
.css-jvrjj8-MuiPaper-root-MuiAppBar-root{
  background: none !important;
  box-shadow: none !important;
}
.css-1v5z4dq-MuiPaper-root-MuiCard-root {
  background-color: transparent !important;
  border: transparent !important;
}
.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper{
  background-color:#f6e9e7 !important;
  border: 1px solid #54330f;
  width: 150px;
}
.css-1okfn8i{
  border: none !important;
}
/* Tab Selected  */
.css-1bbfivk-MuiButtonBase-root-MuiTab-root.Mui-selected{
  color: #54330f !important;
}
#vertical-tabpanel-2{
  width: 80%;
}
.text-gradient {
  /* text-transform: uppercase !important; */
  background: #61dafb !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  /* text-shadow: 0 1px 0 linear-gradient(to right, #30CFD0 0%, #330867 100%); */
  /* font: {
		size: 20vw;
		family: $font;
	}; */
}
.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background:#af7a43;
  transform-origin: 0%;
}
.carousel-indicators [data-bs-target]{
  background-color: #a6743f ;
}
.carousel-indicators{
  margin-bottom: 0px !important;
}
.ButtonCard{
  background-color: #f6e9e7 !important;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.ButtonCardText{
  color: #a6743f;
  text-transform: uppercase !important;
  height: 70px;
  font-weight: 500;
  font-size: larger;
}
.ButtonCardAction{
  background-color: #794c1d !important ;
  border: none;
}
.ButtonCardAction:hover{
  background-color:#a6743f !important;
  color: wheat;
  border: none !important;
}
.CustomCard{
  background-color: #f6e9e7 !important;
  
}
.CustomCardHeader{
  color: #a6743f!important;
  font-weight: 600;
  text-transform: uppercase !important;
  font-size: 24px;
}
.CustomCardText{
  color: #794c1d;
}
.Slides{
  margin-top: 20px;

  
}
.carousel{
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  height: auto;
}
.Footer{
  margin-top: 20px;
}
.Appbar_nav_btn {
   color: #54330f !important;
   display: block; 
   margin-left: 30px !important;
   font-weight: 600 !important;  
   background-color: transparent !important;
   border: none;
   text-transform: uppercase;
}
.Appbar_nav_btn_menu{
  color: #54330f !important;
  display: block; 
  margin-left: 30px !important;
  font-weight: 600 !important;  
  background-color:  #f6e9e7 !important;
  border: 1px solid #54330f !important;
  text-transform: uppercase;
}
.modal-title{
  color: #54330f !important;
}
.modal-body{
  color: #54330f !important;
}
.modal-content{
  background-color:  #f6e9e7 !important;
  border: 1px solid #54330f !important;
}
.modal-dialog {
  margin-top: 250px;
}


.leaflet-container{
  height: 600px !important;
  width: 100% !important;
}
.leaflet-draw-draw-polygon{
  background-color: #54330f !important;
  color: wheat !important;
}
.leaflet-draw-actions a{
  color: #54330f !important;
  text-decoration: none !important;
  font-weight: 800;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
  background-color: #f6e9e7 !important;
  border: 1px solid #54330f !important;
}
.GoogleLogout button{
  background-color: inherit !important;
}
.LoginTileContent button{
  margin-top: 50px !important;
}